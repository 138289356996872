// Font Families
$g-font-primary: "Roboto", serif;
$g-font-secondary: "Roboto", serif;

//
// Colors
//

// Primary Color
$g-color-primary: #394349;
$g-color-primary-light-v1: lighten($g-color-primary, 20%);
$g-color-primary-dark-v1: darken($g-color-primary, 20%);

// Main Colors
$g-color-text: #425a69;
$g-color-link-text: lighten($g-color-text, 10%);
$g-color-footer-links: lighten($g-color-text, 20%);
$g-color-text-light-v1: #c9cacc;

$g-color-main: #425a69;
$g-color-main-light-v1: lighten($g-color-main, 10%);
$g-color-main-light-v2: lighten($g-color-main, 20%);
$g-color-main-dark-v1: darken($g-color-main, 10%);
$g-color-main-dark-v2: darken($g-color-main, 20%);
$g-color-main-dark-v3: darken($g-color-main, 30%);

$g-color-secondary: #c9cacc;
$g-color-secondary-light-v1: lighten($g-color-secondary, 10%);
$g-color-secondary-light-v2: lighten($g-color-secondary, 15%);
$g-color-secondary-dark-v1: darken($g-color-secondary, 30%);
$g-color-secondary-dark-v2: darken($g-color-secondary, 30%);
